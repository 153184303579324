<template>
  <div class="container g-bg">

    <div class="info-section">
      <div class="title">500</div>
      <div class="desc">
        哎呦，程序出错了...
      </div>
      <div class="desc">
        当前页面无法访问，点击返回首页。
      </div>
      <el-button
        class="btn"
        type="primary"
        @click="$router.replace({name: 'index'})"
      >返回首页</el-button>
    </div>
  </div>
</template>

<script>
export default {}
</script>
<style lang="scss" scoped>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: url('~@/assets/images/404-bg.png') center / cover no-repeat;

  .info-section {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-120%, -100%);

    .title {
      font-size: 100px;
      font-weight: bold;
      color: #04a37e;
    }

    .desc {
      font-size: 14px;
      color: #2b2d42;
    }

    .btn {
      margin-top: 30px;
    }
  }
}
</style>
